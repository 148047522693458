/** @jsxImportSource theme-ui */
import React, { useState } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import GuestMarketing from './GuestMarketing';
import GuestNewsletterSignUpCheckbox from './GuestNewsletterSignUpCheckbox';
import messages from './intl';
import PurchaserDetailsCollapse from './PurchaserDetailsCollapse';
import TermsAndConditions from './TermsAndConditions';

import { actionCreators } from '../../../store/ActionCreators';
import {
  selectBookingData,
  selectIsCustomerReadyForPayment,
} from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import ContainedRow from '../../common/layout/ContainedRow';
import { WidgetData } from '../types';

interface Props {
  widget: WidgetData<'TicketingCMSJourneyPurchaserDetailsWidget'>;
}

const PurchaserDetails: React.FC<Props> = ({ widget }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const bookingData = useSelector(selectBookingData);
  const isCustomerReadyForPayment = useSelector(
    selectIsCustomerReadyForPayment
  );

  const showNewsletterCheckbox = widget.shape?.showNewsletterCheckbox || false;
  const showTermsAndConditionsCheckbox =
    widget.shape?.showTermsAndConditionsCheckbox || false;
  const enableGuestMarketing = widget.shape?.enableGuestMarketing || false;
  const hideGuestMarketingWhenLoggedIn =
    widget.shape?.hideGuestMarketingWhenLoggedIn || false;
  const showGuestNewsletterSignUpCheckbox =
    widget.shape?.showGuestNewsletterSignUpCheckbox || false;
  const showThirdPartyCheckbox = widget.shape?.showThirdPartyCheckbox || false;

  const showNewsLetterSignUp =
    showGuestNewsletterSignUpCheckbox && !bookingData?.isUserValidated;

  const canLaunchPayment = isCustomerReadyForPayment && !showNewsLetterSignUp;

  const [isPageValidated, setIsPageValidated] = useState(!!canLaunchPayment);

  const isReadyForPayment = isPageValidated && isCustomerReadyForPayment;

  const shouldShowGuestMarketingOptions =
    enableGuestMarketing &&
    !(hideGuestMarketingWhenLoggedIn && bookingData.isUserValidated);

  const resetHostedPayment = () => {
    setIsPageValidated(false);
    dispatch(actionCreators.removeHostedPayments());
  };

  const handleValidatePage = () => {
    setIsPageValidated(true);
  };

  return (
    <>
      <ContainedRow styles={{ pb: 6 }}>
        <PurchaserDetailsCollapse
          widget={widget}
          isPageValidated={isPageValidated}
          onEditCustomer={resetHostedPayment}
        />

        {showGuestNewsletterSignUpCheckbox && (
          <GuestNewsletterSignUpCheckbox widget={widget} />
        )}

        {shouldShowGuestMarketingOptions && (
          <GuestMarketing
            widget={widget}
            showNewsletterCheckbox={showNewsletterCheckbox}
            showThirdPartyCheckbox={showThirdPartyCheckbox}
            onEdit={resetHostedPayment}
          />
        )}

        <TermsAndConditions
          widget={widget}
          isPageValidated={isPageValidated}
          showTermsAndConditionsCheckbox={showTermsAndConditionsCheckbox}
          onEdit={resetHostedPayment}
        />
      </ContainedRow>

      {!isReadyForPayment && (
        <ActionButton
          onClick={handleValidatePage}
          showIcon
          contained
          showWarningMessage={isPageValidated && !isReadyForPayment}
          warningTitle={formatMessage(messages.formErrorSubtitle)}
          warningMessage={formatMessage(messages.formErrorMessage)}
          mx={5}
          variant='primary'
          showCartSummaryButtonOnMobile
        >
          {formatMessage(messages.continueToPaymentButtonText)}
        </ActionButton>
      )}
    </>
  );
};

export default PurchaserDetails;
