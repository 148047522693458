/** @jsxImportSource theme-ui */
import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Grid } from 'theme-ui';

import FandBItemImage from './FandBItemImage';

import { Concession, ConcessionPricing } from '../../../@types/modelTypes';
import { displayPrice } from '../../../services/Helpers';
import {
  findModifiedConcessionItemCost,
  hasModifiersWithCost,
} from '../../../services/KioskHelpers';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectConfig,
  selectCurrencyConfig,
  selectImageProcessorContainer,
  selectImageProcessorUrl,
  selectSelectedFaBConcessions,
} from '../../../store/Selectors';

interface Props {
  concession: Concession;
  showImageColumn: boolean;
}

const FandBItem: React.FC<Props> = ({ concession, showImageColumn }) => {
  const dispatch = useDispatch();

  const config = useSelector(selectConfig);
  const currencyConfig = useSelector(selectCurrencyConfig);
  const imageProcessorUrl = useSelector(selectImageProcessorUrl);
  const imageProcessorContainer = useSelector(selectImageProcessorContainer);
  const selectedConcessions = useSelector(selectSelectedFaBConcessions);

  const concessionIndexInTheSelectedConcessions =
    selectedConcessions.list.findIndex((c) => c.id === concession.id);

  const isSelectedConcession = concessionIndexInTheSelectedConcessions > -1;

  const imagePath = concession.image
    ? concession.image.replace('https://', '')
    : null;

  const itemPricing: ConcessionPricing =
    findModifiedConcessionItemCost(concession);
  const showPrice =
    !hasModifiersWithCost(concession) && itemPricing.costIncTax > 0;

  const hideTax = config.currentCinema.hideTax;

  const imageUrl: string = imagePath
    ? `${imageProcessorUrl}/${imageProcessorContainer}/${imagePath}?height=100`
    : '/images/concession-fallback.png';

  const modalContext = isSelectedConcession ? 'update' : 'add';
  const concessionIndex = isSelectedConcession
    ? concessionIndexInTheSelectedConcessions
    : undefined;

  return (
    <Grid
      gap={0}
      columns={['1fr auto']}
      data-testid='f-and-b-item'
      sx={{ marginTop: 6 }}
    >
      <Box>
        <div>
          <Button
            variant='link'
            onClick={() =>
              dispatch(
                actionCreators.setFandBItemModalData(
                  concession,
                  modalContext,
                  true,
                  concessionIndex
                )
              )
            }
            sx={{ fontWeight: 'bold' }}
          >
            {concession.description}
          </Button>
        </div>
        {showPrice && (
          <Box sx={{ mt: 1 }}>
            {displayPrice(
              hideTax ? itemPricing.costIncTax : itemPricing.costMinusTax,
              currencyConfig
            )}
          </Box>
        )}
      </Box>
      {showImageColumn && (
        <Box>
          <Button
            variant='link'
            onClick={() =>
              dispatch(
                actionCreators.setFandBItemModalData(
                  concession,
                  modalContext,
                  true,
                  concessionIndex
                )
              )
            }
          >
            <FandBItemImage
              concession={concession}
              imageUrl={imageUrl}
              showQuantityIcon
            />
          </Button>
        </Box>
      )}
    </Grid>
  );
};

export default FandBItem;
